export class StudentModel  {
  constructor() { 
    this.phones = [];
  }
   id: string;
    dType: string;
    uId: string;
    aId: string;
    type: string;
    cifFormSubmitted: boolean;
    firstName: string;
    middleName: string;
  lastName: string;
  testCompleted: boolean;
      referralCodeUsed: string;
  parentName: string;
  parentPhoneNumber: string;
  parentEmailAddress: string;
    dob: any;
      age: number;
    gender: string;
  hobbies: Hobby[];
   aboutMe: string;
   dreamCompany: string;
    dreamCollege: string;
   educationalBackground: string;
     careerAspirations_My: string;
  careerAspirations_Parents: string;
  careerAspirations_After5Years: string;
  educational: Educational[];
  addresses: Address[];
    phones: Phone[]
    emails:Email [];
      examLocation: string;
  selectedCareerPaths: CP[];
    IsDemographicDataPresent: boolean;
     isCIFDataPresent: boolean;
  favouriteSubjects: string[];
  subjectsIn8_10: string[];
  scoresIn8: string[];
  scoresIn9: string[];
  scoresIn10: string[];
  scoresIn11: string[];
  scoresIn12: string[];
  subjectsIn11_12: string[];
  extraCurricularActivities: string[];
  strength: string[];
  weaknesses: string[];
  weaknesses_My: string[];
  weaknesses_Parents: string[];
  familyAnnualIncome: string;
  roleModel: string[];
  fatherWorksAt: string;
  fatherWorksAs: string;
  motherWorksAt: string;
  motherWorksAs: string;
  medicalHistory: string;
    testCompletedOn: Date;
    createdBy: string;
    updatedBy: string;
    createdOn: number;
    language: Language[];
    archieved: boolean;
    active: boolean;
    paidPackageSelected:boolean;
}
export class Language { 
  title: string;
}
export class Email {
  type: string;
  address: string;
  verified: boolean;
}
export class Address {
  type: string;
  line1: string;
  line2: string;
  city: string;
  postalCode: string;
  state: string;
  country: string;
  latitude: string;
  longitude: string;
}
export class StudentStatus {
  'studentUId': string;
  'phoneVerified': boolean;
  'emailVerified': boolean;
  'packageSelected': boolean;
  'testGiven': boolean;
  'counsellingScheduled': boolean;
  'counsellingCompleted': boolean;
  'careerPathSelected': boolean;
  'dashboardVisited': boolean;
}
export class Hobby { 
  hobby: string;
  id: string
}
export class Phone {
  number: string;
  verified: boolean;
}
export class CP {
  CareerPath: string;
  CareerPathUId: string;
  Checked: Boolean;
  Count: number;
}
export class Educational {
  qualificationUId: string;
  title: string;
  year: string;
  board: string;
  stream: string;
  instituteDetails: string;
  universityDetails: string;
  score: Score[]
}
export class Score {
  subject: string;
  marks: string;
  grade: string;
}
export class StudentCurrentPackage {
    id: string;
    dType: string;
    uId: string;
    subscribeDate: Date;
    basicReportIncluded: boolean;
    advancedReportIncluded: boolean;
    currency: string;
    amount: 0;
    couponCode: string;
    studentUid: string;
    packageUid: string;
    packageName: string;
    archieved: boolean;
    active: boolean;

}
